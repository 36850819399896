import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiPageTemplate, EuiSpacer } from "@elastic/eui";
import React, { useEffect, useState } from "react";
import StigData from "./redhat-7.json"


const Redhat7StigViewer = ({ id }) => { 

    const [currentStig, changeCurrentStig] = useState({})
    const [currentColor, changeCurrentColor] = useState('info')
    const [currentIcon] = useState('alert')

    useEffect(() => {
        let stig = StigData.stig.findings[id]
        changeCurrentStig(stig)
        switch (stig.severity) {
            case "critical":
                changeCurrentColor("danger")
                break;
            case "high":
                changeCurrentColor("danger")
                break;
            case "medium":
                changeCurrentColor("warning")
                break;
            case "low":
                changeCurrentColor("info")
                break;
            case "info":
                changeCurrentColor("info")
                break;
            default:
                break;
        }
    }, [id])
    


    return <EuiPageTemplate    
    
    pageHeader={{
      iconType: "https://avatars3.githubusercontent.com/u/25011180?s=280&v=4",
      pageTitle: 'CyberSift STIG Compliance Alerts',
    }}
  >
    <EuiFlexGroup direction="column">                               

      <EuiFlexItem>
        <EuiFlexGroup direction="column" gutterSize="l">
            <EuiCallOut title={`${currentStig.id} - ${currentStig.title}`} color={currentColor} iconType={currentIcon}>
                
            <EuiSpacer />
                        
            <p style={{fontSize: "medium"}}>
                <strong>Title: </strong> { currentStig.title }
            </p>
            <p style={{fontSize: "medium"}}>
                <strong>Severity:</strong> {currentStig.severity }
            </p>
            <p style={{fontSize: "medium"}}>
                <strong>Description:</strong> { currentStig.description }
            </p>
            <p style={{fontSize: "medium"}}>
                <strong>Check:</strong> { currentStig.checktext }
            </p>
            <p style={{fontSize: "medium"}}>
                <strong>Fix</strong>: { currentStig.fixtext }
            </p>
        </EuiCallOut>        
        </EuiFlexGroup>
      </EuiFlexItem>
      
    </EuiFlexGroup>
  </EuiPageTemplate>  


}

export default Redhat7StigViewer