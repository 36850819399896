import React from 'react'
import {
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPageTemplate,
    EuiSideNav,    
    EuiIcon,
    EuiHorizontalRule,
  } from '@elastic/eui';

const sideNavItems = [
  {
    name: 'Tutela Alerts',
    icon: <EuiIcon type="starPlusFilled" />,
    id: 0,
    items: [
      {
          name: 'SSH Weak Algorithms or Ciphers Enabled',
          id: 10,
          description: <>
            <p>
            Nessus has detected that the remote SSH server is configured to use the Arcfour stream cipher or CBC ciphers.
            RFC 4253 advises against using Arcfour due to an issue with weak keys, and CTR Mode Ciphers should be used instead of CBC
            </p>
            <p>              
              <EuiHorizontalRule size="quarter" />
              <strong>CVE-2008-5161</strong>
              <br />
              While <a href="https://exchange.xforce.ibmcloud.com/vulnerabilities/46620">CVE-2008-5161</a> specifically mentions "SSH Tectia", it also
              applies to OpenSSH and it states:
            </p>
            <p>
              [...]a remote attacker to obtain sensitive information, caused by the improper handling of errors within an SSH session which is encrypted with a block cipher algorithm in CBC mode. A remote attacker with read and write access to network data could exploit this vulnerability to display plaintext bits from a block of ciphertext and obtain sensitive information.
            </p>
            <p>
            Contact the vendor or consult product documentation to disable Arcfour and CBC cipher algorithms.
            </p>
          </>,
          icon: <EuiIcon type="arrowRight" />
      }                             
    ],
  }  
];

const SSHWeakAlgos = () => {


  const cardNodes = sideNavItems.flatMap((section, _) => {
    return section.items
  }).map(function (item, index) {
    return (
      <EuiFlexItem key={index}>
        <EuiCard
          id={`alert-${item.id}`}
          title={`${item.name}`}
          style={{textAlign: "left"}}
          description={item.description}
        />
      </EuiFlexItem>
    );
  });
      
    
  return <EuiPageTemplate
      pageSideBar={   
      <EuiSideNav
        aria-label="FAQ Sidenav"
        items={sideNavItems}
        truncate={false}
      />
    }
      
      pageHeader={{
        iconType: "https://avatars3.githubusercontent.com/u/25011180?s=280&v=4",
        pageTitle: 'CyberSift Tutela Alerts',
      }}
    >
      <EuiFlexGroup direction="column">                               

        <EuiFlexItem>
          <EuiFlexGroup direction="column" gutterSize="l">{cardNodes}</EuiFlexGroup>
        </EuiFlexItem>
        
      </EuiFlexGroup>
    </EuiPageTemplate>    
  

}

export default SSHWeakAlgos