import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import {
    EuiCard,
    EuiCodeBlock,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHorizontalRule,
    EuiPageTemplate,
    EuiSideNav,    
    EuiIcon,
    EuiImage,
    EuiSpacer,
    EuiFieldSearch,
    EuiHealth
  } from '@elastic/eui';
import innerText from 'react-innertext';
import FeatureMap from './FeatureMap';

const sideNavItems = [
  {
    name: 'Getting Started',
    icon: <EuiIcon type="starPlusFilled" />,
    id: 0,
    items: [
      {
          name: 'Quickstart',
          id: 10,
          description: <>
            <ol>
                <li>
                  Login to the Tutela admin portal and note your API key, or create a new one
                  <EuiSpacer />
                  <EuiImage
                      size="l"
                      hasShadow
                      allowFullScreen
                      caption="Click for full size"
                      alt="Screenshot"
                      src="/img/agent-management.png"
                  />
                  <EuiSpacer />
                </li>
                <li>
                  Edit the "Agent Targets" and enter the targets the agent should scan:
                  <EuiSpacer />
                  <EuiImage
                      size="l"
                      hasShadow
                      allowFullScreen
                      caption="Click for full size"
                      alt="Screenshot"
                      src="/img/add-target.png"
                  />
                  <EuiSpacer />
                  Targets should be inserted each on a seperate line, and format may be:
                  <ul>
                    <li> A single IP Address </li>
                    <li> An IP subnet (example: 192.168.1.0/24) </li>
                    <li> A domain name (example: scanme.nmap.org) </li>
                  </ul>
                </li>
                <li>
                  Run following on a server where docker is installed:
                  
                  <EuiCodeBlock
                      language="bash"
                      fontSize="m"
                      paddingSize="m"
                      isCopyable>
                          {"docker run dvassallocs/tutela --api_key <INSERT_YOUR_API_KEY_HERE>"}
                  </EuiCodeBlock>
                </li>                                
            </ol>
          </>,
          href: '#faq-10',
          icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'May I view the how-to screencast again?',
        id: 6,
        description: <iframe style={{
            padding:20, 
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto"
          }} 
          title="Video Intro" 
          width="560" 
          height="800" 
          src="https://www.youtube.com/embed/BwdYsZo--9s" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen
        />,
        href: '#faq-6',
        icon: <EuiIcon type="arrowRight" />
      },                              
    ],
  },
  {
    name: 'Network-based Asset Management',
    icon: <EuiIcon type="storage" />,
    id: 1639045921,
    items: [
      {
        name: 'What is Network-based Asset Management?',
        id: 16390459211,
        description: <>
          <p>
            Output from network scans are used to populate a database of assets. Each asset is fingerprinted and where possible the asset is tagged with:
            <ol>
              <li>type of device (e.g. windows, linux, server, etc...)</li>
              <li>Public / Private IP</li>
              <li>Newly Detected Asset</li>
            </ol>
          </p>
          <p>
            Each asset is given a "state" (see next FAQ for more details) and the dashboard allows you to easily query assets in different states for easy 
            reporting and compliance checks
          </p>
          <EuiImage
              size="l"
              hasShadow
              allowFullScreen
              caption="Click for full size"
              alt="Screenshot"
              src="/img/network-asset-management.png"
          />
        </>,
        href: '#faq-16390459211',
        icon: <EuiIcon type="arrowRight" />
      },{
        name: 'What are Network-based Asset Management "states"?',
        id: 16390459212,
        description: <>
          <p>
            A network asset can take on one of three states:
            <EuiSpacer />
              <EuiHealth color={"success"}>Authorized</EuiHealth>
            <EuiSpacer />
              <EuiHealth color={"warning"}>Under Investigation</EuiHealth>
            <EuiSpacer />
              <EuiHealth color={"danger"}>Unseen</EuiHealth>
            <EuiSpacer />
          </p>
          <p>
            When an asset is initially detected, it is put in the "unseen" state. This indicates to analysts that the asset hasn't been reviewed yet.
            Once an asset is detected, an analyst can put the asset state to "under investigation" while appropriate checks are carried our to see if
            said asset is to be allowed on the network. Tutela allows the analyst to store "notes" pertaining to the asset to add better context to the 
            asset. Once investigations are completed, the asset can be moved to "authorized" to indicate concluded investigations.
          </p>
        </>,
        href: '#faq-16390459212',
        icon: <EuiIcon type="arrowRight" />
      }
    ]
  },
  {
    name: 'Network-based Vulnerability Scanning',
    icon: <EuiIcon type="timeline" />,
    id: 20,
    items: [
      {
        name: 'What are the agent requirements?',
        id: 1,
        description: <>
          <p>
            The agent is packaged as a single linux container, hence it only requires a valid docker environment. If you run a windows host docker server, you will need to have
            either WSL2 or Hyper-V enabled. If this is not possible for some reason, please see the procedure outlined in <a href='#faq-1657107866'>"How do I install the native
            windows network scanner?"</a>
          </p>
        </>,
        href: '#faq-1',
        icon: <EuiIcon type="arrowRight" />
      },{
        name: 'How do I install/update the agent?',
        id: 9,
        description: <>
          <p>
            You can do this by executing the following on a server where docker is installed:
              
            <EuiCodeBlock
                language="bash"
                fontSize="m"
                paddingSize="m"
                isCopyable>
                    {"docker pull dvassallocs/tutela"}
            </EuiCodeBlock>         
            
            If the container is already installed, re-running the above will update to the latest version.              

          </p>
        </>,
        href: '#faq-9',
        icon: <EuiIcon type="arrowRight" />
      },   
      {
        name: 'How do I install the native windows network scanner?',
        id: 1657107866,
        description: <>
          <p>
            The docker method outlined above is the easier and more convenient option, however there are some cases when you'd like to install the network scanner on
            a windows server which doesn't support running linux docker containers. In this case an alternative native windows agent is provided which has a slightly more
            involved (though still easy) installation process:
          </p>
              
          <ol>
            <li>
                Install python 3 for windows from <a href="https://www.python.org/ftp/python/3.10.5/python-3.10.5-amd64.exe">the official download page</a>
            </li>
            <li>
                Add the python path to the system environment variables
            </li>
            <li>
              Test that the above worked by opening a NEW admin powershell prompt and running the command <code>python</code>.
              <br></br>
              This should successfully open a python prompt
            </li>
            <li>
                Download and install the NMAP scanner for windows <a href="https://nmap.org/download#:~:text=Latest%20stable%20release%20self%2Dinstaller">from their official site</a>
            </li>
            <li>
              <a href="https://csinstallscripts.blob.core.windows.net/cs-vas/tutela-windows-network-scanner.zip">Download the windows native scanner</a>
            </li>
            <li>
              Extract the zip file to a convenient location (e.g. C:\Users\administrator), and open an admin powershell prompt in this directory
            </li>
            <li>
              If necessary, modify the powershell execution policy to allow for script execution (e.g. "<code>Set-ExecutionPolicy Unrestricted</code>")
            </li>
            <li>
              Execute the PS script <strong>windows_installer.ps1</strong>
              <br></br>
              This should complete without any errors
            </li>
            <li>
              Test successfull installation by opening a new shell and completing the following two commands succesfully:
              <ul>
                <li> <code>nmap</code> </li>
                <li> <code>C:\your-path-here\tutela-windows-network-scanner\agent.exe</code> </li>
              </ul>
            </li>
          </ol>
          
        </>,
        href: '#faq-1657107866',
        icon: <EuiIcon type="arrowRight" />
      },   
      {
        name: 'How Do I Add More Agents in the portal?',
        id: 2,
        description: <>
          <p>
            There are two options to accomplish this:
              
              <ul style={{marginTop: 7}}>
                <li>
                  Use the "setup wizard"
                </li>
                <li>
                  Manually via the Agents section
                </li>
              </ul>

            You are not limited in the number of agents and corresponding API keys you can generate. Ideally you should have seperate agnets / API keys 
            for each subset of related targets you are planning to scan

          </p>
        </>,
        href: '#faq-2',
        icon: <EuiIcon type="arrowRight" />
      },{
        name: 'How Do I Add More IP Address Targets to scan?',
        id: 1633420041,
        description: <>
          <p>
            There are two options to accomplish this:
              
              <ul style={{marginTop: 7}}>
                <li>
                  Via the web portal <strong>(recommended)</strong>
                </li>
                <li>
                  Via the agent command line interface
                </li>
              </ul>

            Adding targets via the web portal is straightforward:
            <EuiSpacer />
            <EuiImage
                size="l"
                hasShadow
                allowFullScreen
                caption="Click for full size"
                alt="Screenshot"
                src="/img/add-target-portal.png"
            />
            <EuiSpacer />
            <p>
              Targets will then be automatically pushed to the agent on the next scan.
            </p>
            <p>
              Adding targets via the CLI involves using the following command line arguments when running the agent 
              <a href="./#faq-3"> (see this FAQ)</a>:
              <code>
              -targets string 
                <br/>
                  &nbsp;&nbsp;&nbsp; targets seperated by spaces enclosed within quotes (e.g. "8.8.8.8 4.2.2.2")
                <br/>
              -targets_file string
                <br/>
                  &nbsp;&nbsp;&nbsp; targets file  
              </code>
            </p>              
          </p>
        </>,
        href: '#faq-1633420041',
        icon: <EuiIcon type="arrowRight" />
      } ,{
        name: 'How Do I Exclude IP Addresses from  the Targets to scan?',
        id: 16341156791,
        description: <>
          <p>

            Excluding targets via the web portal is done by simply prepending the target with a negative sign: "-", for example: <br />
            <code>
              -192.168.1.2
            </code>
            <EuiSpacer />
            <EuiImage
                size="l"
                hasShadow
                allowFullScreen
                caption="Click for full size"
                alt="Screenshot"
                src="/img/exclude-target.png"
            />
            <EuiSpacer />
            <p>
              In the example above, 192.168.0.0/24 subnet will be scanned, except for 192.168.1.1. Targets will then be automatically excluded in the next scan.
            </p>              
          </p>
        </>,
        href: '#faq-16341156791',
        icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'May I run both internal and external network scans?',
        id: 201,
        description: <>
          <p>
            Yes! Since you are not limited in the number of agents and corresponding API keys you can generate, it is possible and encouraged that you:
            <ol>
              <li>
                Generate an agent to be used for internal scanning with "Targets" set to internal addresses such as "192.168.0.0/24"
              </li>
              <li>
                Generate an agent to be used for external scanning with "Targets" set to external addresses such as "scanme.nmap.org"
              </li>
            </ol>              
            As long as each agent has network connectivity to it's targets this will work. If you'd like to scan your own external footprint 
            from an external endpoint <strong>please let us know and contact support who will arrange for this service to be provided to you</strong>
          </p>
        </>,
        href: '#faq-201',
        icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'Agent Command Line Settings',
        id: 3,
        description: <>
          <strong>Recommended Use :</strong>
          <EuiCodeBlock
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                {'docker run -v "$(pwd)/results:/app/results" dvassallocs/tutela --api_key INSERT_API_KEY_HERE'}
            </EuiCodeBlock>     
          <em>
            TIP: Note the use of a bind volume mount. This will store the latest raw scan on the agent's disk as well as uploading the raw scan to the Tutela server. Useful in 
            cases where raw scan uploads get large and susceptible to network timeouts during uploads
          </em>
          <EuiSpacer />
          To see all the available options:
          <EuiSpacer />
          <EuiCodeBlock
            language="bash"
            fontSize="m"
            paddingSize="m"
            isCopyable>
              {`docker run dvassallocs/tutela --help
              
CyberSift Tutela Agent
======================

Usage of ./csVasAgentLinux:
-api_key string
      Use an api key instead of public IP (contact support)
-mass_scan
      Enable Mass Scanning (WARNING: this will increase the scanning time considerably and SHOULD NOT be carried out over the internet)
-max_connections int
      maximum simultaneous connections to open during scanning (default 500) (default 500)
-port_timeout int
      maximum port timeout to use during scanning (default 50ms). Bigger values are more accurate but scans take longer (default 50)
-scan
      run scan (default true)
-scan_name string
      scan name (default "cs-vas")
-targets string
      targets seperated by spaces enclosed within quotes (e.g. "8.8.8.8 4.2.2.2")
-targets_file string
      targets file  
              `}
          </EuiCodeBlock>            
        </>,
        href: '#faq-3',
        icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'What does "Exploit Available" mean?',
        id: 4,
        description: <>
          <p>
            The vulnerability assessment tool will display all appropriate CVEs. However, a common problem is the sheer number of CVEs which 
            are published on a daily baisis. In order to help you cut through the noise and prioritize those vulnerabilities that really matter, we 
            also check exploit databses for vulnerabilites which have exploit code vailable to them as this significantly increases the chances of 
            said vulnerability being successfully attacked. This is a known way of properly prioritizing which CVEs should be addressed first: 
            <EuiHorizontalRule size="half"></EuiHorizontalRule>
            <ul>
              <li>
                <a rel="noreferrer"  href="https://www.helpnetsecurity.com/2021/02/10/vulnerability-triage/" target="_blank">When it comes to vulnerability triage, ditch CVSS and prioritize exploitability</a>
              </li>
            </ul>
          </p>
        </>,
        href: '#faq-4',
        icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'How do I run a scan periodically?',
        id: 5,
        description: <>
          <p>
            The easiest way of doing this is to add a cron job which calls the Tutela Agent container perdiocally. For example:
          </p>
          <EuiCodeBlock
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                {'0 1 20 * * docker run dvassallocs/tutela --api_key INSERT_YOUR_API_KEY'}
          </EuiCodeBlock> 
          <p>
            The above cronjob  will launch a scan on the 20th day of every month
          </p>

        </>,
        href: '#faq-5',
        icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'Why do I see vulnerabilities with CVE ID "CS-VER-UNKNOWN"?',
        id: 1634715321,
        description: <>
          <p>
            In some cases after a network vulnerability scan you would see entries similar to the following, with "CVE" set to "CS-VER-UNKNOWN":
          </p>
          <EuiImage
                size="l"
                hasShadow
                allowFullScreen
                caption="Click for full size"
                alt="Screenshot"
                src="/img/cs-ver-unknown.png"
            />
          <EuiSpacer />
          <p>
            <strong>TL;DR: this means the scanner was unable to determine the version of software running, and such alerts can in general be safely resolved or ignored</strong>
          </p>
          <p>
            The reason the above appear is to alert you to the fact that the agent is unable to determine the version of software running on the given port. 
            In the above screenshot example, the product was determined to be "NGINX", however the version could not be determined. Rather than spamming you 
            with inaccurate vulnerability entries, the scanner generates a "meta-alert" named "CS-VER-UNKONWN" (a.k.a CyberSift-VERsion-UNKNOWN). The alert 
            is not necessarily a bad indicator, in fact during many pentesting engagements customers are encouraged to disabled headers and other data which
            can leak information such as version numbers that may aid an attacker. 
          </p>            
        </>,
        href: '#faq-1634715321',
        icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'How can I give scans a friendly name to better identify them on the dashboard?',
        id: 1634715331,
        description: <>
          <p>
            By default, a unique name is generated by the agent whenever a scan is uploaded. You can influence the name that is generated by using:
          </p>
          <EuiCodeBlock
            language="bash"
            fontSize="m"
            paddingSize="m"
            isCopyable>
              {`-scan_name insert-scan-name-here`}
          </EuiCodeBlock>    
          <p>
            as an additional argument whenever you call the agent. 
          </p>
          <EuiSpacer />
          <p>
            The above argument is valid for both infrastructure and web application scans 
          </p>            
        </>,
        href: '#faq-1634715331',
        icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'What is "Sentinel Mode"?',
        id: 8,
        description: 'Sentinel Mode places any given agent into a special mode which allows it to scan all private IP subnets continuously. This allows '
        + "you to continously monitor all your internal network without any pre-configured schedule or any pre-configured tagets. This mode is meant "
        + "to aid in network discovery and in scenarios where you would like to periodically and randomly crawl your entire network to make sure nothing "
        + "changes without you being alerted. At the moment, the WebUI only supports configuring agents in either Sentinel or 'normal' mode. However you "
        + "can run agents in both Sentinel and normal mode on the same server using the CLI. No configuration is needed on the agent to run in 'Sentinel Mode', "
        + "at the cost of flexibility in assigning targets and schedules to your scans.",
        href: '#faq-8',
        icon: <EuiIcon type="arrowRight" />
      }
    ]
  },
  {
    name: 'Host-based Vulnerability Scanning',
    icon: <EuiIcon type="inspect" />,
    id: 30,
    items: [
      {
        name: 'What is host-based vulnerability scanning?',
        id: 31,
        description: <>
          Host based scanning is similar to network-based scanning, however instead of a docker-based agent which probes your network, this approach
          uses an agent which is installed directly on a PC or server. This give you even greater visibility into your infrastructure as a host based 
          agent is able to enumerate software which is installed on a given PC/Server and detect any CVEs for that software. The host based scanning 
          feature allows you to:
          <EuiSpacer />
          <ul>
            <li>
              Keep track of what software is installed in your environment
            </li>
            <li>
              Keep track of which users are given administrative access to each host the agent is installed on
            </li>
            <li>
              Enumerate known CVEs which affect the software installed on a host
            </li>
            <li>
              Check for missing security best practices against the <a href="https://csrc.nist.gov/Projects/risk-management/sp800-53-controls/release-search#!/800-53">NIST Risk Management Framework SP 800 standard</a>
              (Currently Linux Redhat/Centos only, more OS coming soon)
            </li>
          </ul>
        </>,
        href: '#faq-31',
        icon: <EuiIcon type="arrowRight" />
      },{
        name: 'Windows: How do I install and run the host agent?',
        id: 32,
        description: <>
          <p>
            The Windows MSI can be downloaded from:
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="html"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {"https://csinstallscripts.blob.core.windows.net/cs-vas/tutela.msi"}
            </EuiCodeBlock>     
            The MSI may be installed manually or pushed via an appropriate Group Policy Object
          </p>            
          <p>
            Running the agent is very straightforward. Simply run the below command from an elevated privilege command prompt:
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {'C:\\Program Files (x86)\\Tutela Windows Agent\\winAgent.exe --api_key INSERT_YOUR_API_KEY'}
            </EuiCodeBlock> 
          </p>
          <p>
            Scheduling the agent to run periodically can be easily accomplished using a windows scheduled task setup manually (for an example see below) or setup via an
            appropriate Group Policy Object
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {`SCHTASKS /CREATE /RU "NT AUTHORITY\\SYSTEM" /RL HIGHEST /NP /SC WEEKLY /D SUN /TN "Tutela\\weekly" /TR "'C:\\Program Files (x86)\\Tutela Windows Agent\\winAgent.exe' --api_key KEYHERE" /ST 03:00"`}
            </EuiCodeBlock> 
          </p>
        </>,
        href: '#faq-32',
        icon: <EuiIcon type="arrowRight" />
      },        
      {
        name: 'Linux: How do I install and run the host agent?',
        id: 33,
        description: <>
          <p>
            The linux agent is supplied as single binary and can be downloaded from:
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="html"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {"https://csinstallscripts.blob.core.windows.net/cs-vas/TutelaLinuxAgent"}
            </EuiCodeBlock>     
          </p>            
          <p>
            Running the agent is very straightforward. Simply run the below command from a root terminal:
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {'./TutelaLinuxAgent -api_key INSERT_YOUR_API_KEY'}
            </EuiCodeBlock> 
          </p>
          <p>            
            The agent does not need to be "installed", simply executed, and can be run on a schedule using CRON. An example provisioning bash script which
            downloads, and schedules the scan is provided below:
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {`#!/bin/bash

export HTTP_PROXY=http://change-or-remove.proxy.com
export HTTPS_PROXY=http://change-or-remove.proxy.com

cd /usr/local/bin
wget https://csinstallscripts.blob.core.windows.net/cs-vas/TutelaLinuxAgent
chmod +x TutelaLinuxAgent

echo '''#!/bin/bash 
/usr/local/bin/TutelaLinuxAgent -api_key INSERT_YOUR_API_KEY''' > /etc/cron.monthly/tutela.sh
chmod +x /etc/cron.monthly/tutela.sh
`}
            </EuiCodeBlock> 
          </p>
        </>,
        href: '#faq-33',
        icon: <EuiIcon type="arrowRight" />
      },
    ]
  },
  {
    name: 'Supply Chain (Library) Vulnerability Scanning',
    icon: <EuiIcon type="layers" />,
    id: 1639747517,
    items: [
      {
        name: 'What is supply chain (library) vulnerability scanning?',
        id: 16397475171,
        description: <>
          Library based scanning is a module included in the host agent. It allows you to scan development "artifacts" for known vulnerabilities (like the infamous Log4jShell)
          The following is a non-exhaustive list of support "artifacts":
          <EuiSpacer />
          <ul>
            <li>
              Java: JAR, WAR 
            </li>
            <li>
              NodeJS: yarn.lock
            </li>
            <li>
              Python: Pipfile.lock, requirements.txt
            </li>
          </ul>           
          At the moment, only <strong>Linux</strong> servers are supported
        </>,
        href: '#faq-16397475171',
        icon: <EuiIcon type="arrowRight" />
      },{
        name: 'How do I run the library vulnerability scan?',
        id: 16397475172,
        description: <>
          <p>
            At the moment, only <strong>Linux</strong> servers are supported. The library vulnerability scan is included in the linux host agent, so make sure the
            agent is installed on a linux server, by running the following command:
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {"curl -o TutelaLinuxAgent https://csinstallscripts.blob.core.windows.net/cs-vas/TutelaLinuxAgent && chmod +x TutelaLinuxAgent"}
            </EuiCodeBlock>                 
          </p>            
          <p>
            Running the agent is straightforward, you simply need to specify your API key and the directory wherein the artifact resides, for example:
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {"./TutelaLinuxAgent -api_key YOUR_API_KEY -artifact_directory /home/tutelaTest"}
            </EuiCodeBlock>
          </p>
          <p>
            By default, results are reported back to the Tutela online dashboard, however you have the option to save the results locally in JSON format by using the following:
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {"./TutelaLinuxAgent -api_key YOUR_API_KEY -artifact_directory /home/tutelaTest -artifcat_local_output results.json"}
            </EuiCodeBlock>
          </p>
          <p>
            Scheduling the agent to run periodically can be easily accomplished using a cron job with the above command
          </p>
        </>,
        href: '#faq-16397475172',
        icon: <EuiIcon type="arrowRight" />
      },        
    ]
  },
  {
    name: 'Docker Image Vulnerability Scanning',
    icon: <EuiIcon type="compute" />,
    id: 1664883107,
    items: [
      {
        name: 'What is docker image vulnerability scanning?',
        id: 1664883107,
        description: <>
          Docker is a virtualization system based on the concept of "containers". Each container is in effect a self contained operating system, running underneath the supervision of the
          host server running docker. As such, each container can include it's own vulnerabilities depending on which image is used. For example, a container based on an Ubuntu image
          would need to be checked for Ubuntu-specific vulnerabilities. Each container would also need to be scanned for library vulnerabilities (see "Supply Chain scanning" above).
          
          
          <EuiSpacer />
          
          Both types of scanning are supported by Tutela via a module included in the host agent. It allows you to scan saved docker images for known vulnerabilities (both as OS and library level where supported)          

        </>,
        href: '#faq-1664883107',
        icon: <EuiIcon type="arrowRight" />
      },{
        name: 'How do I run the docker image vulnerability scan?',
        id: 1664883108,
        description: <>
          <p>
            At the moment, only <strong>Linux</strong> servers are supported. The docker image vulnerability scan is included in the linux host agent, so make sure the
            agent is installed on a linux server, by running the following command:
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {"curl -o TutelaLinuxAgent https://csinstallscripts.blob.core.windows.net/cs-vas/TutelaLinuxAgent && chmod +x TutelaLinuxAgent"}
            </EuiCodeBlock>                 
          </p>          
          <p>
            Before running the scan, you need to "export" or "save" the docker container which is to be scanned. Doing this is straightforward. On the docker host server:
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {"docker save --output XYZ.tar container_name:tag"}
            </EuiCodeBlock>      
            An important note: You <strong>must</strong> specify a tag or the scan would fail. For example, for the tutela docker image, the proper command would be <code>docker save --output tutela.tar dvassallocs/tutela:latest</code>. 
            Of course, change the output argument to anything you wish. The name of the tar file is used by Tutela to group vulnerabilities from the same docker image together.
          </p>
          <p>
            Running the agent is straightforward, you simply need to specify your API key and the TAR file from the step above, for example:
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {"./TutelaLinuxAgent -api_key YOUR_API_KEY -artifact_directory YOUR_TAR_FILE.tar"}
            </EuiCodeBlock>
          </p>
          <p>
            By default, results are reported back to the Tutela online dashboard, however you have the option to save the results locally in JSON format by using the following:
            <EuiCodeBlock
              style={{marginTop: 10, marginBottom: 10}}
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
                  {"./TutelaLinuxAgent -api_key YOUR_API_KEY -artifact_directory YOUR_TAR_FILE.tar -artifcat_local_output results.json"}
            </EuiCodeBlock>
          </p>
          <p>
            Scheduling the agent to run periodically can be easily accomplished using a cron job with the above command
          </p>
        </>,
        href: '#faq-1664883108',
        icon: <EuiIcon type="arrowRight" />
      },        
    ]
  },
  {
    name: 'Web Application Vulnerability Scanning',
    icon: <EuiIcon type="./img/web-app-icon.svg" />,
    id: 1634037547,
    items: [
      {
        name: 'What is Web Application vulnerability scanning?',
        id: 16340375471,
        description: <>
          Web Application scanning allows you to test for common web application vulnerabilities such as the <a rel="noreferrer" target="_blank" href="https://owasp.org/www-project-top-ten/">OWASP Top 10</a>. 
          The web application scanner is shipped as a seperate docker agent which can very easily be setup to scan multiple websites, and configured to scan periodically. 
          The results are then uploaded to the Tutela dashboard to give you a single view of all vulnerabilities detected across your infrastructure.
          <EuiSpacer />
          This feature allows you to:
          <EuiSpacer />
          <ul>
            <li>
              Improve your security by adding missing best-practice HTTP headers to your setup
            </li>
            <li>
              Avoid common HTML / Javascript vulnerabilites such as Injection, Broken Authentication and XSS
            </li>
            <li>
              Map alerts to <a href="https://cwe.mitre.org/about/index.html" target="_blank" rel="noreferrer">MITRE CWEs</a>
            </li>
          </ul>
        </>,
        href: '#faq-16340375471',
        icon: <EuiIcon type="arrowRight" />
      },{
        name: 'How do I install/update the agent?',
        id: 163403754819,
        description: <>
          <p>
            You can do this by executing the following on a server where docker is installed:
              
            <EuiCodeBlock
                language="bash"
                fontSize="m"
                paddingSize="m"
                isCopyable>
                    {"docker pull dvassallocs/tutela-web-scanner"}
            </EuiCodeBlock>         
            
            If the container is already installed, re-running the above will update to the latest version.              

          </p>
        </>,
        href: '#faq-163403754819',
        icon: <EuiIcon type="arrowRight" />
      },{
        name: 'How do I launch a web scan?',
        id: 163403754719,
        description: <>
          <p>
            You can do this by executing the following on a server where docker is installed:
              
            <EuiCodeBlock
                language="bash"
                fontSize="m"
                paddingSize="m"
                isCopyable>
                    {"docker run dvassallocs/tutela-web-scanner --targets 'https://scanme.nmap.org/, https://public-firing-range.appspot.com/' -api <INSERT_YOUR_API_KEY>"}
            </EuiCodeBlock>         
            
            If the container is already installed, re-running the above will update to the latest version. The above example also illustrates scanning two target, and the use of "ZAP Test Plans" which allow for more accurate scanning of web applications protected by
            a login page. 

            <EuiSpacer />

            By default the agent will run a "light" scan, however a more thorough (but time-consuming) active scan can be launch with the following code:

            <EuiCodeBlock
                language="bash"
                fontSize="m"
                paddingSize="m"
                isCopyable>
                    {"docker run dvassallocs/tutela-web-scanner --targets 'https://scanme.nmap.org/, https://public-firing-range.appspot.com/' -api <INSERT_YOUR_API_KEY> -active true"}
            </EuiCodeBlock> 

            <EuiSpacer />

            The agent also accepts selenium test plans written in python (typically exported from SeleniumIDE). Test plans should be reachable from within the agent docker container 
            at the path <strong>/zap/testPlans</strong>, usually achieved by using a bind mount as per the below example:

            <EuiCodeBlock
                language="bash"
                fontSize="m"
                paddingSize="m"
                isCopyable>
                    {"docker run --mount type=bind,source='/demo/demo/TutelaTestPlans',target=/zap/testPlans dvassallocs/tutela-web-scanner --targets 'https://scanme.nmap.org/, https://public-firing-range.appspot.com/' -api <INSERT_YOUR_API_KEY> -active true"}
            </EuiCodeBlock>         

          </p>
        </>,
        href: '#faq-163403754719',
        icon: <EuiIcon type="arrowRight" />
      },      
          
    ]
  },
  {
    name: 'Cloud Provider Intergration',
    id: 1633439452,
    icon: <EuiIcon type="cloudSunny" />,
    items: [
      {
        name: 'What is Cloud Provider Intergration used for?',
        id: 1633439459,
        description: <>
        Cloud Provider Integration is used to enumerate resources which are provisioned in your Cloud Provider, giving you greater visibility
        into your infrastructure. The cloud provider integration currently supports the following providers:
        <ul>
          <li>
            Azure Compute
          </li>
          <li>
            Amazon Web Services EC2
          </li>
        </ul>
        </>,
        href: '#faq-1633439459',
        icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'How do I setup AWS Integration?',
        id: 1633439460,
        description: <>
        AWS Integration involves creating an App ID and secret for a dedicated Tutela user via Amazon IAM. 
        <ol style={{marginTop: 10}}>
          <li>
            <p>
              Sign into the AWS console and navigate to the IAM service:
            </p>
            <EuiSpacer />
            <EuiImage
                size="l"
                hasShadow
                allowFullScreen
                caption="Click for full size"
                alt="Screenshot"
                src="/img/aws-integration-1.png"
            />
            <EuiSpacer />
          </li>
          <li>
            <p>
              Add a new user, with an appropriate username and select the "Acces key - Programmatic Access" option:
            </p>
            <EuiSpacer />
            <EuiImage
                size="l"
                hasShadow
                allowFullScreen
                caption="Click for full size"
                alt="Screenshot"
                src="/img/aws-integration-2.png"
            />
            <EuiSpacer />
          </li>
          <li>
            <p>
              (Recommended) Place the newly-created user into a dedicated newly-created group:
            </p>
            <EuiSpacer />
            <EuiImage
                size="l"
                hasShadow
                allowFullScreen
                caption="Click for full size"
                alt="Screenshot"
                src="/img/aws-integration-3.png"
            />
            <EuiSpacer />
          </li>
          <li>
            <p>
              (Recommended) Name the group, and assign the "AmazonEC2ReadOnlyAccess" policy ONLY:
            </p>
            <EuiSpacer />
            <EuiImage
                size="l"
                hasShadow
                allowFullScreen
                caption="Click for full size"
                alt="Screenshot"
                src="/img/aws-integration-4.png"
            />
            <EuiSpacer />
          </li>
          <li>
            <p>
              Make a note of the Access key ID (a.k.a Identifier) and the Secret access key (a.k.a Secret):
            </p>
            <EuiSpacer />
            <EuiImage
                size="l"
                hasShadow
                allowFullScreen
                caption="Click for full size"
                alt="Screenshot"
                src="/img/aws-integration-5.png"
            />
            <EuiSpacer />
          </li>
          <li>
            <p>
              {`Sign into the Tutela console and navigate to Settings > Agent Management > Add Cloud Provider :`}
            </p>
            <EuiSpacer />
            <EuiImage
                size="l"
                hasShadow
                allowFullScreen
                caption="Click for full size"
                alt="Screenshot"
                src="/img/aws-integration-6.png"
            />
            <EuiSpacer />
          </li>
          <li>
            <p>
              Insert the appropriate details recorded in step 5 above, ensuring that the "Amazon Web Services" option is selected:
            </p>
            <EuiSpacer />
            <EuiImage
                size="l"
                hasShadow
                allowFullScreen
                caption="Click for full size"
                alt="Screenshot"
                src="/img/aws-integration-7.png"
            />
            <EuiSpacer />
          </li>
        </ol>
        <p>
          <strong>Please note! Cloud Provider assets may take up to 24 hours to be shown in the Tutela dashboard</strong>
        </p>
        </>,
        href: '#faq-1633439460',
        icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'How do I setup Azure Integration?',
        id: 1633439470,
        description: <>
        Azure Integration involves creating a Service Principal for a dedicated Tutela App: 
          <ol style={{marginTop: 10}}>
            <li>
              <p>
                Sign into the Azure console and open a cloud shell:
              </p>
              <EuiSpacer />
              <EuiImage
                  size="l"
                  hasShadow
                  allowFullScreen
                  caption="Click for full size"
                  alt="Screenshot"
                  src="/img/azure-integration-1.png"
              />
              <EuiSpacer />
            </li>
            <li>
              <p>
                Enter the following command:
                <EuiCodeBlock>
                  az ad sp create-for-rbac --name TUTELA
                </EuiCodeBlock>
              </p>
              <EuiImage
                  size="l"
                  hasShadow
                  allowFullScreen
                  caption="Click for full size"
                  alt="Screenshot"
                  src="/img/azure-integration-2.png"
              />
              <EuiSpacer />
            </li>
            <li>
              <p>
                Make a note of the output, specifically the Identifier, Secret, and Tenant ID:
              </p>
              <EuiSpacer />
              <EuiImage
                  size="l"
                  hasShadow
                  allowFullScreen
                  caption="Click for full size"
                  alt="Screenshot"
                  src="/img/azure-integration-3.png"
              />
              <EuiSpacer />
            </li>
            <li>
              <p>
                Next is noting your subscription ID. Search for "Subscriptions" in the Azure portal:
              </p>
              <EuiSpacer />
              <EuiImage
                  size="l"
                  hasShadow
                  allowFullScreen
                  caption="Click for full size"
                  alt="Screenshot"
                  src="/img/azure-integration-4.png"
              />
              <EuiSpacer />
            </li>
            <li>
              <p>
                Note the appropriate Subscription ID assigned to your account:
              </p>
              <EuiSpacer />
              <EuiImage
                  size="l"
                  hasShadow
                  allowFullScreen
                  caption="Click for full size"
                  alt="Screenshot"
                  src="/img/azure-integration-5.png"
              />
              <EuiSpacer />
            </li>
            <li>
              <p>
                {`Sign into the Tutela dashboard, and navigate to Settings > Agent management > Add Cloud Provider. 
                Insert the required information gathered from the previous steps into the dialog box, taking special care to select the 
                "Azure Compute" option:`}
              </p>
              <EuiSpacer />
              <EuiImage
                  size="l"
                  hasShadow
                  allowFullScreen
                  caption="Click for full size"
                  alt="Screenshot"
                  src="/img/azure-integration-6.png"
              />
              <EuiSpacer />
            </li>
          </ol>
        </>,
        href: '#faq-1633439470',
        icon: <EuiIcon type="arrowRight" />
      },
    ]
  },
  {
    name: 'External Domain Management',
    id: 40,
    icon: <EuiIcon type="globe" />,
    items: [
      {
        name: 'What is external domain management used for?',
        id: 41,
        description: <>
        External Domain Management is a Tutela feature which allows you to keep an eye on registered subdomains for a given main domain. 
        Apart from listing any detected subdomains, Tutela also lists any resolved IP addresses for each subdomain detected. This allows you as a security administrator to:

        <ol style={{marginTop: 10}}>
          <li>
            Enumerate any subdomains you may not have been aware of. This is a problem especially for larger teams which may unknowingly increase your attack surface without your knowledge
          </li>
          <li>
            Enumerate any leaked internal subdomains which should not be public
          </li>
          <li>
            Be aware of any unreasonable domain IP changes. This may be simply a valid and authorized DNS change, but it may also be a warning sign for a &nbsp;
            <a href="https://www.hackerone.com/application-security/guide-subdomain-takeovers" rel="noreferrer" target="_blank">
              subdomain takeover
            </a>.
          </li>
        </ol>          
        These DNS resolutions are done externally from your own infrastructure, i.e. these checks do not use your DNS servers. This allows you to see your DNS infrastructure from the point of view of a
        potential attacker. 
        </>,
        href: '#faq-41',
        icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'How do I configure external domain management?',
        id: 42,
        description: <>
        In three simple steps:
        <EuiSpacer />
        <EuiImage
            size="l"
            hasShadow
            allowFullScreen
            caption="Click for full size"
            alt="Screenshot"
            src="/img/external-domain-management.png"
        />
        <EuiSpacer />
        Among other domain checks, the above procedure will activate the external domain management feature
        </>,
        href: '#faq-42',
        icon: <EuiIcon type="arrowRight" />
      },  
    ]
  },    
  {
    name: 'Data Leak & Phishing Detection',
    id: 50,
    icon: <EuiIcon type="classificationJob" />,
    items: [
      {
        name: 'What is Data Leak & Phishing Detection?',
        id: 51,
        description: <p>
          Data Leak &amp; Phishing Detection is a Tutela feature which allows security administrators to:
          <ul style={{marginTop: 10}}>
          <li>
              Detect lookalike domains which could be leveraged to launch phishing campaigns 
            </li>
            <li>
              Detect possible cloned sites which are usually used to phish users into disclosing company credentials
            </li>
            <li>
              Detect sensitivie information which may have been disclosed due to
              possible data leaks both intentional and unintentional on the visible and dark web
            </li>
          </ul>
        </p>,
        href: '#faq-51',
        icon: <EuiIcon type="arrowRight" />
      },  
      {
        name: 'How do I configure Data Leak & Phishing Detection?',
        id: 52,
        description: <p>
          This is done in three steps:          
          <EuiSpacer />
          <EuiImage
              size="l"
              hasShadow
              allowFullScreen
              caption="Click for full size"
              alt="Screenshot"
              src="/img/external-domain-management.png"
          />
          <EuiSpacer />
          Once done, Tutela will kick off a number of checks, te results of which can be seen under the "Data Leak &amp; Phishing Detection" section in the UI:
          <EuiImage
              size="l"
              hasShadow
              allowFullScreen
              caption="Click for full size"
              alt="Screenshot"
              src="/img/dlpd.png"
          />
          <EuiSpacer />
          In the "Flagged Domains" section of this screen you will be able to see any results which are picked up by Tutela for the given domain:
          <EuiSpacer />
          <EuiImage
              size="l"
              hasShadow
              allowFullScreen
              caption="Click for full size"
              alt="Screenshot"
              src="/img/flagged-domains.png"
          />
          <EuiSpacer />
          This includes details such as which module flagged the issue (Data Leak Prevention, Darkweb Scanner, Similar Domain Generator, etc...), as well as
          utilities for an analyst to add notes and filter by status ("unseen", "acknowledged", "resolved", etc...)
          <br />
          The Similar Domain Generator module can be seen further down in this page:
          <EuiSpacer />
          <EuiImage
              size="l"
              hasShadow
              allowFullScreen
              caption="Click for full size"
              alt="Screenshot"
              src="/img/suspicious-domains.png"
          />
          <EuiSpacer />
          The table shows a list of "currently monitored suspicious domains", each generated by a number of methods such as Domain Generation Algorithms (DGA), 
          as well as SSL and DNS monitoring.
        </p>,
        href: '#faq-52',
        icon: <EuiIcon type="arrowRight" />
      },  
    ]
  },
  {
    name: 'Reporting',
    id: 100,
    icon: <EuiIcon type="reportingApp" />,
    items: [
      {
        name: 'My browser tab gets unresponsive when generating a PDF report... is this normal?',
        id: 7,
        description: 'This happens when you select a rather large report to be generated. PDF generation happens client-side within your browser, so '
        + 'large reports will cause your browser to become unresponsive until it manages to render all the PDF pages. Usually given enough time (even up'
        + 'to 10 minutes) the browser will display the PDF report - however in these situaitons we recommend you change your report type to the default '
        + '"spreadsheet" since this is generated on our servers and will be significantly faster',
        href: '#faq-7',
        icon: <EuiIcon type="arrowRight" />
      },  
    ]
  }
];

const FAQs = () => {

  const [searchTerm, changeSearchTerm] = useState(null)

  const scrollToHash = () => {
    setTimeout(() => {
      if (window.location.hash !== ""){
        var elmnt = document.getElementById(window.location.hash.replace('#', ''));
        if (elmnt){
          elmnt.scrollIntoView();
        }        
      }
    }, 100);   
  }

  window.onhashchange = scrollToHash;
  

  const [currentItems, changeCurrentItems]=useState(sideNavItems)

  useEffect(()=>{
    if (searchTerm!=null && searchTerm.length>=3){
      let searchTermLower = searchTerm.toLowerCase()
      changeCurrentItems(sideNavItems.filter(entry => entry.items.filter(
          item=> innerText(item.description).toLowerCase().includes(searchTermLower) || item.name.toLowerCase().includes(searchTermLower)
        ).length > 0
      ).map(entry=>({
          ...entry,
          items: entry.items.filter(
            item=> innerText(item.description).toLowerCase().includes(searchTermLower) || item.name.toLowerCase().includes(searchTermLower)
          )
        })
      ))
    } else if (searchTerm==null || searchTerm===""){
      changeCurrentItems(sideNavItems)
    }
  },[searchTerm])

  useLayoutEffect(()=>{
    scrollToHash()
  },[])


  const cardNodes = currentItems.flatMap((section, _) => {
    return section.items
  }).map(function (item, index) {
    return (
      <EuiFlexItem key={index}>
        <EuiCard
          id={`faq-${item.id}`}
          title={`${item.name}`}
          style={{textAlign: "left"}}
          description={item.description}
        />
      </EuiFlexItem>
    );
  });
      
    
  return <EuiPageTemplate
      pageSideBar={<>
      <EuiFieldSearch
        placeholder="Search"
        value={searchTerm}
        isClearable={true}
        onChange={(e)=>changeSearchTerm(e.target.value)}
      />      
      <EuiSideNav
        aria-label="FAQ Sidenav"
        items={currentItems}
        truncate={false}
      />
    </>}
      
      pageHeader={{
        iconType: "https://avatars3.githubusercontent.com/u/25011180?s=280&v=4",
        pageTitle: 'CyberSift Tutela FAQs',
      }}
    >
      <EuiFlexGroup direction="column">                               

        <EuiFlexItem>
          <EuiFlexGroup direction="column" gutterSize="l">{cardNodes}</EuiFlexGroup>
        </EuiFlexItem>

        

        <EuiFlexItem key={999999}>
          <EuiCard
            id={`faq-feature-map`}
            title="Tutela Feature Map"
            style={{textAlign: "left"}}
            description={<FeatureMap/>}
          />
        </EuiFlexItem>


        <EuiFlexItem>
          <div style={{height: "75vh"}} />
        </EuiFlexItem>
        

        </EuiFlexGroup>
    </EuiPageTemplate>    
  

}

export default FAQs