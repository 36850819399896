import React from 'react'
import {
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPageTemplate,
    EuiSideNav,    
    EuiIcon,
  } from '@elastic/eui';

const sideNavItems = [
  {
    name: 'Tutela Alerts',
    icon: <EuiIcon type="starPlusFilled" />,
    id: 0,
    items: [
      {
          name: 'SMB Unauthenticated Enumeration Possible',
          id: 10,
          description: <>
            <p>
              The remote SMB server is configured to allow NULL and/or ANONYMOUS connections via SMB. This allows an attacker to (at minimum):
              <ul>
                <li>
                  Enumerate Local Users and Groups
                </li>
                <li>
                  Enumerate Domain Users
                </li>
                <li>
                  Enumerate domain password policies
                </li>
                <li>
                  Enumerate active sessions / logged in users
                </li>
              </ul>
            </p>
            <p>
              The vulnerability is also an effective pathway to potentially more severe attacks via MiTM attacks or spoofing
            </p>
            
          </>,
          icon: <EuiIcon type="arrowRight" />
      }                             
    ],
  }  
];

const SmbUnauthenticatedEnum = () => {


  const cardNodes = sideNavItems.flatMap((section, _) => {
    return section.items
  }).map(function (item, index) {
    return (
      <EuiFlexItem key={index}>
        <EuiCard
          id={`alert-${item.id}`}
          title={`${item.name}`}
          style={{textAlign: "left"}}
          description={item.description}
        />
      </EuiFlexItem>
    );
  });
      
    
  return <EuiPageTemplate
      pageSideBar={   
      <EuiSideNav
        aria-label="FAQ Sidenav"
        items={sideNavItems}
        truncate={false}
      />
    }
      
      pageHeader={{
        iconType: "https://avatars3.githubusercontent.com/u/25011180?s=280&v=4",
        pageTitle: 'CyberSift Tutela Alerts',
      }}
    >
      <EuiFlexGroup direction="column">                               

        <EuiFlexItem>
          <EuiFlexGroup direction="column" gutterSize="l">{cardNodes}</EuiFlexGroup>
        </EuiFlexItem>
        
      </EuiFlexGroup>
    </EuiPageTemplate>    
  

}

export default SmbUnauthenticatedEnum