import React from 'react'
import {
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPageTemplate,
    EuiSideNav,    
    EuiIcon,
    EuiCodeBlock,
  } from '@elastic/eui';

const sideNavItems = [
  {
    name: 'Windows',
    icon: <EuiIcon type="starPlusFilled" />,
    id: 0,
    items: [
      {
          name: 'Install Tutela XEV via MSI',
          id: 10,
          description: <>
            <EuiCodeBlock
                language="bash"
                fontSize="m"
                paddingSize="m"
                isCopyable>
                    {".\\tutela.msi API_KEY=INSERT_YOUR_API_KEY_HERE INSTALL_SERVICE=yes"}
            </EuiCodeBlock>
            
          </>,
          icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'Install Tutela as CyberSift Agent via MSI',
        id: 11,
        description: <>
          <p>
            This mode of installation should ONLY be used when in conjunction with CyberSift SIEM, to record file activity within specific folders, which is subsequently 
            sent to the CyberSift SIEM
          </p>
          <EuiCodeBlock
              language="bash"
              fontSize="m"
              paddingSize="m"
              isCopyable>
              {`# Required - paths to monitor
New-Item -Path HKLM:\\SOFTWARE -Name Tutela –Force
New-ItemProperty -Path HKLM:\\SOFTWARE\\Tutela -Name "path" -Value “C:\\Folder 1;C:\\Folder 2” -Force

# Optional - exclude processes from monitor
New-ItemProperty -Path HKLM:\\SOFTWARE\\Tutela -Name "excludedprocs" -Value “example1.exe;example2.exe” -Force

# Required - execute MSI
.\\tutela.msi CYBERSIFT_AGENT=yes INSTALL_SERVICE=yes`}
          </EuiCodeBlock>
          
        </>,
        icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'Force Uninstall Tutela MSI',
        id: 12,
        description: <>
          <p>
            In general Tutela can be uninstalled in the usual Windows fashion, i.e. by using "Add/Remove" programs in the control panel. However in certain
            situations the installation process may have been corrupted due to wrong or unexpected installation flags. If the usual uninstallation procedure 
            does not properly complete, the best option would be to download and execute the Microsoft Troubleshooter found here:
          </p>
          <p>
            <a href="https://support.microsoft.com/en-us/topic/fix-problems-that-block-programs-from-being-installed-or-removed-cca7d1b6-65a9-3d98-426b-e9f927e1eb4d">
             Fix problems that block programs from being installed or removed
            </a>
          </p>
          
        </>,
        icon: <EuiIcon type="arrowRight" />
      },
      {
        name: 'Tutela CyberSift Agent not sending events to CyberSift SIEM',
        id: 13,
        description: <>
          <p>
            For some reason, you are not seeing events that are suppoed to be generated by teh Tutela Agent being populated in the CyberSift SIEM
          </p>
          <ul>
            <li>
              Please ensure the "CyberSift Tutela Agent" Windows Service is "RUNNING"
            </li>
            <li>
              Open Task Manager and check for the presence of both "winAgent.exe" and "EtwReader.exe". If one or the other is missing please restart the tutela windows service and ensure the executables appear
            </li>
            <li>
              Tutela CyberSift Agent stores a copy of the generated events under the "Application" windows event logs. Open the windows event log viewer, navigate to the "Application" logs and check for the presence of any
              events generated by Tutela
            </li>
          </ul>          
        </>,
        icon: <EuiIcon type="arrowRight" />
      },
    ],
  },
  {
    name: 'Linux',
    icon: <EuiIcon type="starPlusFilled" />,
    id: 0,
    items: [
      {
          name: 'Installation on Ubuntu/Centos/RHEL',
          id: 10,
          description: <>
            <ul>
              <li>
                Download the agent (contact us for the URL)
              </li>
              <li>
                Make sure the downloaded file executable (<code>chmod +x</code>)
              </li>
              <li>
                Specify a cron job to run the executable (for example every week)
                <br />
                Make sure to specify a valid <code>-api_key</code> argument otherwise no results will be shown in the Tutela dashboard
              </li> 
            </ul>
          </>,
          icon: <EuiIcon type="arrowRight" />
      }                             
    ],
  } 
];

const InstallAgent = () => {


  const cardNodes = sideNavItems.flatMap((section, _) => {
    return section.items
  }).map(function (item, index) {
    return (
      <EuiFlexItem key={index}>
        <EuiCard
          id={`alert-${item.id}`}
          title={`${item.name}`}
          style={{textAlign: "left"}}
          description={item.description}
        />
      </EuiFlexItem>
    );
  });
      
    
  return <EuiPageTemplate
      pageSideBar={   
      <EuiSideNav
        aria-label="FAQ Sidenav"
        items={sideNavItems}
        truncate={false}
      />
    }
      
      pageHeader={{
        iconType: "https://avatars3.githubusercontent.com/u/25011180?s=280&v=4",
        pageTitle: 'CyberSift Tutela Agent Installation and Deployment',
      }}
    >
      <EuiFlexGroup direction="column">                               

        <EuiFlexItem>
          <EuiFlexGroup direction="column" gutterSize="l">{cardNodes}</EuiFlexGroup>
        </EuiFlexItem>
        
      </EuiFlexGroup>
    </EuiPageTemplate>    
  

}

export default InstallAgent