import React from 'react'
import { ResponsiveSankey } from '@nivo/sankey'

const featureData = {
    "nodes": [
        {
            "id": "DNS Subdomain Discovery",
        },
        {
            "id": "Dark Web Scanning",
        },
        {
            "id": "Authenticated Scans",        
        },
        {
            "id": "Clone Site Detection",        
        },
        {
            "id": "Data Leak Detection",        
        },
        {
            "id": "Network Vulnerability Scanning",        
        },
        {
            "id": "External Network Asset Discovery",        
        },
        {
            "id": "Internal Network Asset Discovery",        
        },
        {
            "id": "Host Software Vulnerability Scanning",        
        },      
        {
            "id": "Compliance Checks",        
        },
        {
            "id": "Host Forensics",        
        },
        {
            "id": "Integrate into Pentesting Workflow",
        },{
            "id": "Data Leak And Phishing Detection",
        },{
            "id": "Host Based Scanning",
        },{
            "id": "Network Based Scanning",
        },{
            "id": "PDF Reports",
        },{
            "id": "Spreadsheet Reports",
        },{
            "id": "Cloud Server Vulnerabilities",
        },{
            "id": "Cloud Server Discovery",
        },{
            "id": "Cloud Provider Integration",
        }
    ],
    "links": [  
        {
            "source": "Cloud Server Discovery",
            "target": "Cloud Provider Integration",
            "value": 100
        },
        {
            "source": "Cloud Server Discovery",
            "target": "Network Based Scanning",
            "value": 100
        },
        {
            "source": "Cloud Server Vulnerabilities",
            "target": "Host Based Scanning",
            "value": 100
        },  
        {
            "source": "Cloud Server Vulnerabilities",
            "target": "Network Based Scanning",
            "value": 100
        },  
        {
            "source": "Spreadsheet Reports",
            "target": "Host Based Scanning",
            "value": 100
        },  
        {
            "source": "Spreadsheet Reports",
            "target": "Network Based Scanning",
            "value": 100
        },
        {
            "source": "PDF Reports",
            "target": "Host Based Scanning",
            "value": 100
        },  
        {
            "source": "PDF Reports",
            "target": "Network Based Scanning",
            "value": 100
        },    
        {
            "source": "DNS Subdomain Discovery",
            "target": "Data Leak And Phishing Detection",
            "value": 100
        },
        {
            "source": "Data Leak Detection",
            "target": "Data Leak And Phishing Detection",
            "value": 100
        },
      {
        "source": "Dark Web Scanning",
        "target": "Data Leak And Phishing Detection",
        "value": 100
      },
      {
        "source": "Clone Site Detection",
        "target": "Data Leak And Phishing Detection",
        "value": 100
      },
      {
        "source": "Authenticated Scans",
        "target": "Host Based Scanning",
        "value": 100
      },
      {
        "source": "Compliance Checks",
        "target": "Host Based Scanning",
        "value": 100
      },
      {
        "source": "Host Forensics",
        "target": "Host Based Scanning",
        "value": 100
      },
      {
        "source": "Integrate into Pentesting Workflow",
        "target": "Network Based Scanning",
        "value": 100
      },
      {
        "source": "Integrate into Pentesting Workflow",
        "target": "Host Based Scanning",
        "value": 100
      },
      {
        "source": "Host Software Vulnerability Scanning",
        "target": "Host Based Scanning",
        "value": 100
      },
      {
        "source": "Network Vulnerability Scanning",
        "target": "Network Based Scanning",
        "value": 100
      },
      {
        "source": "Internal Network Asset Discovery",
        "target": "Network Based Scanning",
        "value": 100
      },
      {
        "source": "External Network Asset Discovery",
        "target": "Network Based Scanning",
        "value": 100
      }
    ]
  }

const FeatureMap = () => {
    
    return  <div style={{height: "85vh"}}>
    
        <ResponsiveSankey
            data={featureData}
            align="justify"
            colors={{ scheme: 'category10' }}
            nodeOpacity={1}
            margin={{ top: 40, right: 200, bottom: 40, left: 250 }}
            nodeHoverOthersOpacity={0.35}
            nodeThickness={18}
            nodeSpacing={24}
            nodeBorderWidth={0}
            nodeBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.8 ] ] }}
            nodeBorderRadius={3}
            nodeTooltip={"div"}
            linkTooltip={"div"}
            linkOpacity={0.5}
            linkHoverOthersOpacity={0.1}
            linkContract={3}
            enableLinkGradient={true}
            labelPosition="bottom"
            labelOrientation="horizontal"
            labelPadding={16}
            labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1 ] ] }}
        />
    </div>    
}

export default FeatureMap