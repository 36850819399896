import React from 'react'
import {
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPageTemplate,
    EuiSideNav,    
    EuiIcon,
    EuiHorizontalRule,
  } from '@elastic/eui';

const sideNavItems = [
  {
    name: 'Tutela Alerts',
    icon: <EuiIcon type="starPlusFilled" />,
    id: 0,
    items: [
      {
          name: 'SSH Weak MAC Algorithms Enabled',
          id: 10,
          description: <>
            <p>
              The remote SSH server is configured to allow either MD5 or 96-bit MAC algorithms, both of which are considered weak. 
              These algorithms exist in the majority of SSH configurations and are generally considered Low Risk.
            </p>
            <p>
              <strong>WHAT ARE SSH WEAK MAC ALGORITHMS?</strong>
              <EuiHorizontalRule size="quarter" />
              As with most encryption schemes, SSH MAC algorithms are used to validate data integrity and authenticity. 
              A ‘MAC algorithm’ should not be conflated with a MAC (Message Authentication Code) as these are two distinct components. 
              The MAC algorithm uses a message and private key to generate the fixed length MAC.
            </p>
            <p>
              MAC algorithms may be considered weak for the following reasons:
              <ol>
                <li>
                  A known weak hashing function is used (MD5)
                </li>
                <li>
                  The digest length is too small (Less than 128 bits)
                </li>
                <li>
                  The tag size is too small (Less than 128 bits)
                </li>
              </ol>
            </p>
            <p>
            Contact the vendor or consult product documentation to disable MD5 and 96-bit MAC algorithms.
            </p>
          </>,
          icon: <EuiIcon type="arrowRight" />
      }                             
    ],
  }  
];

const SSHWeakMAC = () => {


  const cardNodes = sideNavItems.flatMap((section, _) => {
    return section.items
  }).map(function (item, index) {
    return (
      <EuiFlexItem key={index}>
        <EuiCard
          id={`alert-${item.id}`}
          title={`${item.name}`}
          style={{textAlign: "left"}}
          description={item.description}
        />
      </EuiFlexItem>
    );
  });
      
    
  return <EuiPageTemplate
      pageSideBar={   
      <EuiSideNav
        aria-label="FAQ Sidenav"
        items={sideNavItems}
        truncate={false}
      />
    }
      
      pageHeader={{
        iconType: "https://avatars3.githubusercontent.com/u/25011180?s=280&v=4",
        pageTitle: 'CyberSift Tutela Alerts',
      }}
    >
      <EuiFlexGroup direction="column">                               

        <EuiFlexItem>
          <EuiFlexGroup direction="column" gutterSize="l">{cardNodes}</EuiFlexGroup>
        </EuiFlexItem>
        
      </EuiFlexGroup>
    </EuiPageTemplate>    
  

}

export default SSHWeakMAC