import '@elastic/eui/dist/eui_theme_dark.css';
import { useEffect, useState } from 'react';
import SmbUnauthenticatedEnum from './components/alerts/SmbUnauthenticatedEnum';
import SSHWeakAlgos from './components/alerts/SSHWeakAlgos';
import SSHWeakMAC from './components/alerts/SSHWeakMAC';
import SSLTLSSuboptimal from './components/alerts/SSLTLSSuboptimal';
import FAQs from './components/FAQs';
import InstallAgent from './components/InstallAgent';
import Redhat7StigViewer from './components/stigs/Redhat7StigViewer';
import Redhat8StigViewer from './components/stigs/Redhat8StigViewer';
import Ubuntu2004StigViewer from './components/stigs/Ubuntu2004StigViewer';
import Win10StigViewer from './components/stigs/Win10StigViewer';
import Win2019StigViewer from './components/stigs/Win2019StigViewer';

function App() {

  const [content, changeContent]=useState(<></>)

  useEffect(()=>{
    const hostname = window.location.host
    const urlSearch = window.location.search
    if (hostname === "alerts.tutela.cybersift.io"){      
      switch (urlSearch) {
        case "?SSHWeakMAC":
          changeContent(<SSHWeakMAC />)
          return
        case "?SSHWeakAlgos":
          changeContent(<SSHWeakAlgos />)
          return        
        case "?SSL-TLS-Suboptimal":
          changeContent(<SSLTLSSuboptimal />)
          return  
        case "?SMB-Unauthenticated-Enum":
          changeContent(<SmbUnauthenticatedEnum />)
          return 
        default:
          changeContent(<SSHWeakMAC />)
          return
      }      
    } else if (hostname === "ubuntu-20-04.stigs.tutela.cybersift.io") {
      changeContent(<Ubuntu2004StigViewer id={urlSearch.substring(1)} />)
    } else if (hostname === "rh7.stigs.tutela.cybersift.io") {
      changeContent(<Redhat7StigViewer id={urlSearch.substring(1)} />)
    } else if (hostname === "rh8.stigs.tutela.cybersift.io") {
      changeContent(<Redhat8StigViewer id={urlSearch.substring(1)} />)
    } else if (hostname === "win10.stigs.tutela.cybersift.io") {
      changeContent(<Win10StigViewer id={urlSearch.substring(1)} />)
    } else if (hostname === "win2019.stigs.tutela.cybersift.io") {
      changeContent(<Win2019StigViewer id={urlSearch.substring(1)} />)
    } else {
      debugger
      switch (urlSearch) {      
        case "?install-agent":
          changeContent(<InstallAgent />)
          return 
        default:
          changeContent(<FAQs />)
          return
      }       
    }
  },[])

  return (
    <div className="App">
      {content}
    </div>
  );
}

export default App;
