import React from 'react'
import {
    EuiCard,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPageTemplate,
    EuiSideNav,    
    EuiIcon,
  } from '@elastic/eui';

const sideNavItems = [
  {
    name: 'Tutela Alerts',
    icon: <EuiIcon type="starPlusFilled" />,
    id: 0,
    items: [
      {
          name: 'SSL/TLS Suboptimal Ciphers used',
          id: 10,
          description: <>
            <p>
              The remote SSH server is configured to allow suboptimal cipher suites. 
              Cipher suites are rated according to the <a href="https://www.ssllabs.com/projects/rating-guide/">Qualys SSL Server Rating Guide</a>, which 
              rates ciphers from A to F.
            </p>
            <p>
              This alert is triggered when at least one or more ciphers detected has a rating of "B" or lower
            </p>            
            <p>
            Contact the vendor or consult product documentation to disable insecure ciphers.
            </p>
          </>,
          icon: <EuiIcon type="arrowRight" />
      }                             
    ],
  }  
];

const SSLTLSSuboptimal = () => {


  const cardNodes = sideNavItems.flatMap((section, _) => {
    return section.items
  }).map(function (item, index) {
    return (
      <EuiFlexItem key={index}>
        <EuiCard
          id={`alert-${item.id}`}
          title={`${item.name}`}
          style={{textAlign: "left"}}
          description={item.description}
        />
      </EuiFlexItem>
    );
  });
      
    
  return <EuiPageTemplate
      pageSideBar={   
      <EuiSideNav
        aria-label="FAQ Sidenav"
        items={sideNavItems}
        truncate={false}
      />
    }
      
      pageHeader={{
        iconType: "https://avatars3.githubusercontent.com/u/25011180?s=280&v=4",
        pageTitle: 'CyberSift Tutela Alerts',
      }}
    >
      <EuiFlexGroup direction="column">                               

        <EuiFlexItem>
          <EuiFlexGroup direction="column" gutterSize="l">{cardNodes}</EuiFlexGroup>
        </EuiFlexItem>
        
      </EuiFlexGroup>
    </EuiPageTemplate>    
  

}

export default SSLTLSSuboptimal